import { VerifiedIcon } from '@storefront/ui-components/shared/icons';
import { Badge } from './Badge';

export const PetOwnersBadge = () => {
  return (
    <Badge
      label="Great for Pet Owners"
      className="bg-gray-200 text-sm"
      icon={<VerifiedIcon size={24} className="text-gray-700" />}
    />
  );
};
