import { Tooltip } from '@storefront/ui-components/shared/tooltip';
import clsx from 'clsx';
import type { ProductCondition } from 'storefront-client';
import { HelpIcon } from '../icons';

export interface ProductConditionBadgeProps {
  condition: string;
  isRounded?: boolean;
  RootContainer?: React.ElementType;
}

const productCondition: Record<ProductCondition, { title: string; content: string }> = {
  BRAND_NEW: {
    title: 'Brand New',
    content: 'This is a brand new product.',
  },
  LIKE_NEW: {
    title: 'Like New',
    content:
      'This product has been refurbished by our certified technicians. It may have been previously opened or used.',
  },
  LIGHTLY_LOVED: {
    title: 'Lightly Loved',
    content: 'This product has some imperfections and may have been previously opened or used.',
  },
} as const;

export const ProductConditionBadge = ({ condition, isRounded = false, RootContainer }: ProductConditionBadgeProps) => {
  const conditionConfig = productCondition[condition as keyof typeof productCondition];

  const content = (
    <>
      {conditionConfig?.title}
      {isRounded && <HelpIcon className="w-4 h-4 text-gray-500" />}
    </>
  );

  const finalRootContainer = RootContainer ? (
    <RootContainer>{content}</RootContainer>
  ) : (
    <div
      className={clsx('inline-flex items-center leading-6 min-w-max gap-2.5 text-sm', {
        'rounded-full border border-gray-300 font-normal text-gray-600 px-2.5 py-1': isRounded,
        'overflow-hidden text-gray-900 whitespace-nowrap font-roboto font-bold text-xs leading-4 tracking-tighter':
          !isRounded,
      })}
    >
      {content}
    </div>
  );

  return <Tooltip content={conditionConfig?.content}>{finalRootContainer}</Tooltip>;
};
